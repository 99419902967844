import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../../config";
import axios from "axios";

const NewNotesUg = ({username, password, type}) => {

    const [adding, setAdding] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newLink, setNewLink] = useState("");
    const [newPaid, setNewPaid] = useState("false");
    const [searchParams] = useSearchParams();

    const createNotes = async() => {
        try{
            const data = type === 'cuet-ug' ? {
                username,
                password,
                id: searchParams.get('id'),
                title: newTitle,
                link: newLink,
                paid: newPaid

            } : {
                username,
                password,
                id: searchParams.get('id'),
                sem: searchParams.get('sem'),
                title: newTitle,
                link: newLink,
                paid: newPaid
            }

            const endpoint = `${config.rootEndpoint}/${type}/notes/`;
            const response = await axios.post(endpoint, data);

            if(response.status === 201){
                alert('Notes Created Successfully!');
                window.location.reload();
            }

        }catch(error){
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return(
        <>
            <h1 className="headline element">Add Notes</h1>

            {adding ? (
                <div className="notesLink form element">
                    <input type = "text" placeholder="Title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />

                    <input className="link" type = "text" placeholder="Link" value={newLink} onChange={(e) => setNewLink(e.target.value)}/>

                    <select value={newPaid} onChange={(e) => setNewPaid(e.target.value)}>
                        <option disabled>--PAID--</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>

                    <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newLink} target="blank">View</a>

                    <button className="btn-primary" onClick={createNotes}>Create</button>
                </div>

            ) : (
                <div className="element">
                    <button className="btn-secondary" onClick = {() => {setAdding(true)}}>Add New Notes</button>
                </div>
            )}
        </>
    )
}

export default NewNotesUg;