import axios from "axios";
import { useEffect, useState } from "react";

import Content from "../../../layouts/Content/Content";
import config from "../../../../config";
import { useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import AuthenticationForm from "../../../layouts/AuthenticationForm/AuthenticationForm";
import NotesWrapperCuetUg from "../../../layouts/NotesWrapperCuetUg/NotesWrapperCuetUg";

const Notes = () => {

    const [notes, setNotes] = useState([]);
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem('password')));
    const [searchParams] = useSearchParams();

    const getNotes = async () => {
        try {
            const id = searchParams.get('id');
            const response = await axios.get(`${config.rootEndpoint}/optionals/notes/?id=${id}`);
            return response.data;

        } catch (error) {
            return [];
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getNotes();
            setNotes(data);
        })();
    }, []);

  return (
    <div>
        <Stack
            sx={{justifyContent: 'center', alignItems: 'center'}}
            p={4}
            bgcolor='cadetblue'
            mb={4}
        >
            <h1>{searchParams.get('name')} Notes</h1>
        </Stack>

        <Stack rowGap={4}>
            <Content 
                username={username}
                password={password}
                contentDetails={notes.content}
                category='notes'
                type='optionals'
            />

            <NotesWrapperCuetUg
                notes={notes}
                username={username}
                password={password}
                type='optionals'
            />

            <AuthenticationForm
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
            />
        </Stack>

    </div>
  )
}

export default Notes;