import { useRef } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import "./ContentEditor.css";

const ContentEditor = ({content, setContent}) => {
    const editor = useRef(null);

    const createListItems = () => {
        const query = ".joditEditorWrapper h2, .joditEditorWrapper h3, .joditEditorWrapper h4";
        const headings = Array.from(document.querySelectorAll(query));
        let listItems = "";

        for(let i = 0; i < headings.length; i++) {
            const id = `toc-${i}`;
            headings[i].id = id;
            listItems += `<li><a href="#${id}">${headings[i].textContent}</a></li>`;
        }

        return listItems;
    }

    const generateTableOfContents = (editor) => {
        const listItems = createListItems();

        let tableOfContentsWrapper = `
            <div class="tableOfContentsWrapper" id="tableOfContentsWrapper">
                <h3>Table Of Contents:-</h3>
                <ul>
                    ${listItems}
                </ul>
            </div>
        `

        editor.selection.insertHTML(tableOfContentsWrapper);
    }

    const config = {
        buttons: [
            ...Jodit.defaultOptions.buttons,
            {
                name: 'TOC',
                exec: (editor) => {
                    generateTableOfContents(editor);
                }
            }
        ]
    }

	return (
        <div className='joditEditorWrapper'>
            <JoditEditor
                ref={editor}
                value={content}
                tabIndex={1}
                config={config}
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                
            />
        </div>
	);
};

export default ContentEditor;