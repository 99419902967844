import { useState } from "react";
import ContentEditor from "../ContentEditor/ContentEditor";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./ContentNew.css";

const ContentNew = ({username, password, category, endpoint}) => {

    const [contributor, setContributor] = useState("");
    const [content, setContent] = useState("");
    const [isAdding, setIsAdding] = useState(false);
    const [searchParams] = useSearchParams();

    const addContent = async () => {
        try{

            const id = searchParams.get('id');
            const semester = searchParams.get('sem');

            if(!username || !password){
                alert('Please enter username and password');
                return;
            }

            const data = {
                username,
                password,
                content,
                contributor,
                id,
                category,
                semester
            }

            const response = await axios.post(endpoint, data);

            if(response.status === 201){
                window.location.reload();
                alert('Content Created Successfully!');
            }

        }catch(error){
            alert(`Failed! ${error.response.data.message}`);
        }
    }

  return (
    <div className="element">
        {isAdding ? (
            <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <ContentEditor 
                    content={content}
                    setContent={setContent}
                />

                <div className="form">
                    <input placeholder="Contributor" type="text" value={contributor} onChange={(e) => setContributor(e.target.value)} />

                    <button className="btn-primary" onClick={addContent}>Add Content</button>
                </div>
            </div>
        ) : (
            <div className="noContentContainer">
                <p>No content is added yet!</p>
                <button className="btn-primary" onClick={() => setIsAdding(true)}>Add Content</button>
            </div>
        )}
    </div>
  )
}

export default ContentNew;