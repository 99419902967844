import { useEffect, useState } from "react";
import Loader from "../../../layouts/Loader/Loader";
import axios from "axios";
import config from "../../../../config";
import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

const BlogCard = ({ blogId, title, description, lastUpdated }) => {

    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }

    const date = new Date(lastUpdated).toLocaleDateString("en-IN", options);

    return (
        <Card sx={{ width: '100%', backgroundColor: 'darkseagreen' }}>
            <CardContent>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                    <Typography>
                        {date}
                    </Typography>
                </Stack>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {description}
                </Typography>
            </CardContent>

            <CardActions>
                <Link to = {`/blogs?id=${blogId}`}>
                    <Button size="small">View</Button>
                </Link>
            </CardActions>
        </Card>
    )
}

const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchBlogs = async () => {
        try {
            const response = await axios(`${config.rootEndpoint}/blogs/all/`);
            return response.data;

        } catch(error) {
            return [];
        }
    }

    useEffect(() => {
        const getBlogs = async () => {
            const data = await fetchBlogs();
            setBlogs(data);
            setLoading(false);
        }

        getBlogs();

    }, []);

    if(loading) {
        return <Loader text="Loading Blogs" />
    }

    return (
        <>

            <Stack sx={{padding: 1}} rowGap={2}>
                <Stack flexDirection="row" justifyContent="space-between" gap={2} flexWrap="wrap">
                    <Link to={`/`}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                        >
                            Home
                        </Button>
                    </Link>

                    <Link to={`/blogs/create`}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            color="secondary"
                        >
                            New Blog
                        </Button>
                    </Link>
                </Stack>
                
                {blogs.map((blog) => {

                    const { blogId, title, description, lastUpdated } = blog;

                    return (
                        <BlogCard 
                            key={blogId}
                            blogId={blogId}
                            title={title}
                            description={description}
                            lastUpdated={lastUpdated}
                        />
                    )
                })}
            </Stack>
        </>
    )
}

export default Blogs;