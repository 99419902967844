import { useEffect, useState } from "react";
import Content from "../../../layouts/Content/Content.jsx";
import "./SyllabusUG.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../config.js";
import "../../../layouts/AuthenticationForm/AuthenticationForm.jsx";
import AuthenticationForm from "../../../layouts/AuthenticationForm/AuthenticationForm.jsx";

const Syllabus = () => {

    const [syllabus, setSyllabus] = useState({});
    const [link, setLink] = useState("");
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem('password')));

    const getSyllabus = async () => {
        try{
            const id = searchParams.get('id');
            const sem = searchParams.get('sem');

            const endpoint = `${config.rootEndpoint}/ug/syllabus/?id=${id}&sem=${sem}`
            
            const response = await axios.get(endpoint);

            return response.data;

        }catch(error){
            return {};
        }
    }


    useEffect(() => {
        (async () => {
            const data = await getSyllabus();

            setSyllabus(data);
        })();

    }, []);

    useEffect(() => {
        if(syllabus.syllabus?.link){
            setLink(syllabus.syllabus.link);
        }
    }, [syllabus])

    const updateLink = async () => {
        const disciplineID = searchParams.get('id');
        const semester = searchParams.get('sem');

        if(!username || !password){
            alert('Please Enter Username and Password!');
            return;
        }
        
        const data = {
            disciplineID,
            semester,
            link,
            username,
            password
        }

        try{
            const endpoint = `${config.rootEndpoint}/ug/syllabus/`;
            const response = await axios.post(endpoint, data);

            switch(response.status){
                case 201:
                    alert('Syllabus Link added Successfully!');
                    break;

                case 204:
                    alert('Syllabus Link updated Successfully!');
                    break;

                default:
                    alert(`Couldn't update syllabus link. Status code ${response.status}. Please try again later!`);
            }

        }catch(error){
            const { message } = error.response.data;
            alert(`Failed! ${message}`);
        }

    }


    return (
        <div className="syllabus">
            
            <div className="header">
                <h1>{searchParams.get('discipline')} Syllabus</h1>
            </div>

            <Content 
                username={username}
                password={password}
                contentDetails={syllabus.content}
                category='syllabus'
                type='ug'
            />
        
            <div className="element form">
                <input 
                    value = {link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Add Syllabus Link"
                    className=""
                />

                <a href = {link} target="blank" className="btn-secondary"> View </a>
            
                <button className="btn-primary" type="submit" onClick = {updateLink}> Update Syllabus Link </button>
            </div>

            <AuthenticationForm 
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
            />
        </div>
    )
}

export default Syllabus;