import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ContentEditor from "../ContentEditor/ContentEditor";
import AuthenticationForm from "../AuthenticationForm/AuthenticationForm";
import { Link } from "react-router-dom";


const BlogEditor = ({ 
    blogId,
    title, 
    description,
    coverImage,
    content, 
    category, 
    author, 
    tagList, 
    handleSubmit,
    buttonName
}) => {

    const [newTitle, setNewTitle] = useState(title);
    const [newDescription, setNewDescription] = useState(description);
    const [newCoverImage, setNewCoverImage] = useState(coverImage);
    const [newContent, setNewContent] = useState(content);
    const [newCategory, setNewCategory] = useState(category);
    const [newAuthor, setNewAuthor] = useState(author);
    const [newTagList, setNewTagList] = useState(tagList);
    const [newTag, setNewTag] = useState("");
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem('password')));

    const addTag = (newTag) => {
        if(!newTag.trim()) return;

        const updatedList = [...newTagList, newTag.trim()];
        setNewTagList(updatedList);
        setNewTag("");
    }

    const deleteTag = (tag) => {
        const updatedList = newTagList.filter(item => item !== tag);
        setNewTagList(updatedList);
    }

    const getTagsString = (tagList) => {
        const tags = tagList.join(", ");
        return tags;
    }

    const getCurrentDateTime = () => {
        let now = new Date();
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let seconds = now.getSeconds().toString().padStart(2, '0');

        let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return currentDateTime;
    }

    const saveBlog = () => {

        const tags = getTagsString(newTagList);
        const currentDateTime = getCurrentDateTime();

        const data = {
            blogId,
            title: newTitle,
            description: newDescription,
            coverImage: newCoverImage,
            content: newContent,
            category: newCategory,
            author: newAuthor,
            updatedAt: currentDateTime,
            tags,
            username,
            password
        }

        handleSubmit(data);
    }


  return (
    <Stack padding={2} rowGap={4}>

        <TextField 
            sx={{width: '300px'}} 
            label="Title" 
            variant="outlined" 
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
        />

        <TextField 
            sx={{ width: '500px' }}
            label="Description" 
            variant="outlined"
            multiline
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
        />

        <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
            <TextField 
                sx={{ width: '500px' }}
                label="Cover Image" 
                variant="outlined"
                value={newCoverImage}
                onChange={(e) => setNewCoverImage(e.target.value)}
            />

            <Link to={newCoverImage} target="_blank">
                <Button variant="outlined" sx={{height: "100%"}}>
                    View
                </Button>
            </Link>
        </Stack>

        <ContentEditor 
            content={newContent}
            setContent={setNewContent}
        />

        <TextField 
            sx={{ width: '300px' }} 
            label="Category" 
            variant="outlined" 
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
        />

        <TextField 
            sx={{ width: '300px' }} 
            label="Author" 
            variant="outlined" 
            value={newAuthor}
            onChange={(e) => setNewAuthor(e.target.value)}
        />

        <Stack rowGap={1}>
            <Typography variant="h4">
                Tags
            </Typography>
            <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                {newTagList && newTagList.map((tag, index) => (
                    <Stack
                        key={index} 
                        sx={{padding: '8px', backgroundColor: 'cornflowerblue', borderRadius: '8px'}}
                        flexDirection="row" 
                        padding={1}
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                    >
                        <Typography color="white"> {tag} </Typography>

                        <span 
                            style={{color: 'red', cursor: 'pointer'}} 
                            onClick={() => deleteTag(tag)}
                        >
                            X
                        </span>
                    </Stack>
                ))}
            </Stack>
            <Stack flexDirection="row" gap={1} flexWrap="wrap">
                <TextField
                    id="outlined-basic" 
                    label="New Tag" 
                    variant="outlined"
                    value={newTag} 
                    onChange={(e) => setNewTag(e.target.value)}
                />
                <Button variant="outlined" onClick={() => addTag(newTag)}>Add tag</Button>
            </Stack>
        </Stack>

        <AuthenticationForm 
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
        />

        <Button variant="contained" onClick={saveBlog}>{buttonName}</Button>


    </Stack>
  )
}

export default BlogEditor;