import ContentEditor from "../ContentEditor/ContentEditor";
import { useState } from "react";
import axios from "axios";
import "./ContentUpdate.css";

const ContentUpdate = ({contentDetails, username, password, endpoint}) => {

    const [content, setContent] = useState(contentDetails.content);
    const [contributor, setContributor] = useState(contentDetails.contributor);

    const updateContent = async () => {
        try{

            if(!username || !password){
                alert('Please enter username and password');
                return;
            }

            const data = {
                username,
                password,
                content,
                contributor,
                id: contentDetails.contentID
            }

            const response = await axios.put(endpoint, data);

            if(response.status === 204){
                window.location.reload();
                alert('Content Updated Successfully!');
            }

        }catch(error){
            alert(`Failed! ${error.response.data.message}`);
        }
    }

    return (
        <div className="contentUpdate element">
            <ContentEditor 
                content={content}
                setContent={setContent}
            />

            <div className="form">
                <input placeholder="Contributor" type="text" value={contributor} onChange={(e) => setContributor(e.target.value)} />

                <button className="btn-primary" onClick={updateContent}>Update Content</button>
            </div>
        </div>
    )
}

export default ContentUpdate;