import axios from "axios";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import config from "../../../config";

import "./PyqWrapper.css";

const PyqEdit = ({paperID, year, paperLink, type, username, password}) => {

    const [newYear, setNewYear] = useState(year);
    const [newType, setNewType] = useState(type);
    const [newPaperLink, setNewPaperLink] = useState(paperLink);

    const updatePyq = async () => {
        try{
            const data = {
                id: paperID,
                year: newYear,
                type: newType,
                paperLink: newPaperLink,
                username,
                password
            }

            const response = await axios.put(`${config.rootEndpoint}/ug/pyq/`, data);

            if(response.status === 204){
                alert('Pyq updated successfully!');
                window.location.reload();
            }

        } catch(error){
            if(error.response){
                alert(`Failed. ${error.response?.data.message}`);
            }
            else{
                alert(`Failed`);
            }
        }
    }

    const handleInputChange = (value, updaterFunction) => {
        updaterFunction(value);
    }

    return (
        <div className="form element">
            <input type = "number" value={newYear} onChange={(e) => handleInputChange(e.target.value, setNewYear)}/>

            <select value={newType} onChange={(e) => handleInputChange(e.target.value, setNewType)}>
                <option disabled>--Type--</option>
                <option value="solved">Solved</option>
                <option value="unsolved">Unsolved</option>
            </select>

            <input className="link" type = "text" value={newPaperLink} onChange={(e) => handleInputChange(e.target.value, setNewPaperLink)}/>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newPaperLink} target="blank">View</a>

            <button className="btn-primary" onClick={updatePyq}>Save</button>

        </div>
    )
}

const PyqView = ({year, paperLink, type, setEditing, paperID, username, password}) => {

    const deletePyq = async (paperID) => {
        try{
            const data = {
                id: paperID,
                username: username,
                password
            }
    
            const response = await axios.delete(`${config.rootEndpoint}/ug/pyq/`, {
                data
            });
    
            if(response.status === 204){
                alert('Pyq deleted successfully!');
                window.location.reload();
            }
    
        }catch(error){
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return (
        <div className="form element">
            <input type = "text" value={year} disabled/>
            <select value={type} disabled>
                <option value="solved">Solved</option>
                <option value="unsolved">Unsolved</option>
            </select>

            <input className="link" type = "text" value={paperLink} disabled/>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={paperLink} target="blank">View</a>

            <button className="btn-secondary" onClick={() => setEditing(true)}>Edit</button>

            <button className="btn-red" onClick={() => deletePyq(paperID)}>Delete</button>
        </div>
    )
}

const PyqCurrent = ({year, paperLink, type, paperID, username, password}) => {
    const [editing, setEditing] = useState(false);

    return (
        <>
            {editing ? (
                <PyqEdit 
                    year={year}
                    paperLink={paperLink}
                    type={type}
                    paperID={paperID}
                    username={username}
                    password={password}
                />
            ) : (
                <PyqView 
                    year={year}
                    paperLink={paperLink}
                    type={type}
                    paperID={paperID}
                    setEditing={setEditing}
                    username={username}
                    password={password}
                />
            )}
        </>
    )
}

const PyqCurrentWrapper = ({pyq, username, password}) => {
    return (
        <div className="pyqCurrent">
            {pyq && pyq.map((item) => {
                return (
                    <PyqCurrent 
                        key={item.paperID}
                        year={item.year}
                        paperLink={item.paperLink}
                        type={item.type}
                        paperID={item.paperID}
                        username={username}
                        password={password}
                    />
                )
            })}
        </div>
    )
}

const PyqNew = ({username, password}) => {
    const [adding, setAdding] = useState(false);
    const [year, setYear] = useState("");
    const [type, setType] = useState("solved");
    const [paperLink, setPaperLink] = useState("");
    const [searchParams] = useSearchParams();

    const createPyq = async () => {
        try {
            const data = {
                username,
                password,
                id: searchParams.get('id'),
                sem: searchParams.get('sem'),
                year,
                type,
                paperLink
            }

            const endpoint = `${config.rootEndpoint}/ug/pyq/`;
            const response = await axios.post(endpoint, data);

            if(response.status === 201){
                alert('Pyq Created Successfully!');
                window.location.reload();
            }

        } catch (error) {
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return (
        <div className="form element">
            {adding ? (
                <>
                    <input placeholder="Year" type = "number" value={year} onChange={(e) => setYear(e.target.value)}/>
                    
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option disabled>--type--</option>
                        <option value="solved">Solved</option>
                        <option value="unsolved">Unsolved</option>
                    </select>

                    <input placeholder="Link" className="link" type = "text" value={paperLink} onChange={(e) => setPaperLink(e.target.value)}/>

                    <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={paperLink} target="blank">View</a>

                    <button className="btn-primary" onClick={createPyq}>Save</button>
                </>
            ) : (
                <>
                    <button className="btn-secondary" onClick={() => setAdding(true)}>Add Pyq</button>
                </>
            )}
        </div>
    )
}

const PyqWrapper = ({pyq, username, password}) => {
  return (
    <div className="pyqWrapper">
        <h1 className="headline element">PYQ</h1>

        <PyqCurrentWrapper
            pyq={pyq}
            username={username}
            password={password}
        />

        <PyqNew 
            username={username}
            password={password}
        />
    </div>
  )
}

export default PyqWrapper;