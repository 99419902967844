import { CircularProgress, Stack } from "@mui/material"


const Loader = ({text}) => {
  return (
    <Stack sx={{height: '100vh'}} justifyContent="center" alignItems="center" rowGap={2}>
      <CircularProgress />
      <p>{text}</p>
    </Stack>
  )
}

export default Loader