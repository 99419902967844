import ContentUpdate from "../ContentUpdate/ContentUpdate";
import ContentNew from "../ContentNew/ContentNew";
import config from "../../../config";
import "./Content.css";

const Content = ({username, password, contentDetails, category, type}) => {

    return (
        <div>
            <h1 className="headline element">Content</h1>

            {contentDetails ? (
                <ContentUpdate 
                    username={username}
                    password={password}
                    contentDetails={contentDetails}
                    endpoint={`${config.rootEndpoint}/${type}/content/`}
                />
            ) : (
                <ContentNew
                    username={username}
                    password={password}
                    category={category}
                    endpoint={`${config.rootEndpoint}/${type}/content/`}
                />
            )}

            <hr style={{color: "lightgray"}}></hr>
        </div>
    )
}

export default Content;