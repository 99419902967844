import { useState } from "react";
import config from "../../../config.js";
import axios from "axios";

const EditNotes = ({notesID, title, link, paid, setEditing, username, password, type}) => {
    const [newTitle, setNewTitle] = useState(title);
    const [newLink, setNewLink] = useState(link);
    const [newPaid, setNewPaid] = useState(paid);

    const updateNotes = async () => {
        try {
            const data = {
                username,
                password,
                id: notesID,
                title:newTitle,
                link:newLink, 
                paid:newPaid
            }

            const endpoint = `${config.rootEndpoint}/${type}/notes/`;
            
            const response = await axios.put(endpoint, data);

            if(response.status === 204){
                alert('Notes updated successfully!');
                window.location.reload();
            }

        } catch (error) {
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return(
        <div className="form">
            <input type = "text" placeholder="Title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />

            <input className="link" type = "text" placeholder="Link" value={newLink} onChange={(e) => setNewLink(e.target.value)}/>

            <select value={newPaid} onChange={(e) => setNewPaid(e.target.value)}>
                <option disabled>--PAID--</option>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newLink} target="blank">View</a>

            <button className="btn-primary" onClick={updateNotes}>Save</button>
        </div>
    )
}

const Notes = ({notesID, title, link, paid, setEditing, username, password, type}) => {


    const deleteNotes = async () => {
        try {
            const data = {
                id: notesID,
                username,
                password
            }

            const endpoint = `${config.rootEndpoint}/${type}/notes/`;
            
            const response = await axios.delete(endpoint, {
                data
            });

            if(response.status === 204){
                alert('Notes deleted successfully!');
                window.location.reload();
            }
            
        } catch (error) {
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return (
        <div className="form">
            <input type = "text" value={title} disabled/>

            <input className="link" type = "text" value={link} disabled/>

            <select value={paid} disabled>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={link} target="blank">View</a>

            <button className="btn-secondary" onClick={() => setEditing(true)}>Edit</button>

            <button className="btn-red" onClick={deleteNotes}>Delete</button>
            
        </div>
    )
}

const NotesLinkCuetUg = ({notesID, title, link, paid, username, password, type}) => {
    
    const [editing, setEditing] = useState(false);

    return(
        <>
            {editing ? (
                <EditNotes
                    notesID={notesID}
                    setEditing={setEditing}
                    title={title}
                    link={link}
                    paid={paid}
                    username={username}
                    password={password}
                    type={type}
                />
            ) : (
                <Notes
                    notesID={notesID}
                    setEditing={setEditing}
                    title={title}
                    link={link}
                    paid={paid}
                    username={username}
                    password={password}
                    type={type}
                />
            )}
        </>
    )
}

export default NotesLinkCuetUg;