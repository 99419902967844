import DisciplineAccordion from "../DisciplineAccordion/DisciplineAccordion";
import "./CourseWrapper.css"

const CourseWrapper = ({course, disciplines}) => {
  return (
    <div className="courseWrapper">
        <h1 className="course">{course}</h1>

        <div className="disciplinesContainer">
            {
                disciplines.map((item) => {
                    return (
                        <DisciplineAccordion 
                            key={item.disciplineID}
                            disciplineId={item.disciplineID}
                            discipline={item.discipline}
                            list={[1, 2, 3, 4, 5, 6, 7, 8]}
                        />
                    )
                })
            }
        </div>
    </div>
  )
}

export default CourseWrapper;