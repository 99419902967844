import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Stack } from '@mui/material';

const Fallback = ({ text }) => {
  return (
    <Stack height="100vh" justifyContent="center" alignItems="center" gap={2}>
        <SentimentDissatisfiedIcon />
        {text}
    </Stack>
  )
}

export default Fallback;