import Homepage from './components/pages/Homepage/Homepage.jsx';
import { Routes, Route } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import CoursesUg from './components/pages/ug/CoursesUG/CoursesUG.jsx';
import SyllabusUg from './components/pages/ug/SyllabusUG/SyllabusUG.jsx';
import NotesUg from './components/pages/ug/Notes/Notes.jsx';
import PyqUg from './components/pages/ug/Pyq/Pyq.jsx';

import SubjectsCuetUg from "./components/pages/cuet-ug/Subjects/Subjects.jsx";
import NotesCuetUg from './components/pages/cuet-ug/Notes/Notes.jsx';

import SubjectsOptionals from './components/pages/optionals/Subjects/Subjects.jsx';
import NotesOptionals from './components/pages/optionals/Notes/Notes.jsx';

import Blogs from './components/pages/blogs/Blogs/Blogs.jsx';
import BlogDetails from './components/pages/blogs/BlogDetails/BlogDetails.jsx';
import EditBlog from './components/pages/blogs/EditBlog/EditBlog.jsx';
import CreateBlog from './components/pages/blogs/CreateBlog/CreateBlog.jsx';

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
  
      <Routes>
        <Route path = "/du/cuet-ug/subjects/notes" element={<NotesCuetUg />}/>
        <Route path = "/du/cuet-ug/subjects" element={<SubjectsCuetUg />}/>

        <Route path = "/du/ug/courses/syllabus" element={<SyllabusUg />} />
        <Route path = "/du/ug/courses/notes" element={<NotesUg />} />
        <Route path = "/du/ug/courses/pyq" element={<PyqUg />} />
        <Route path = "/du/ug/courses" element={<CoursesUg />} />
        
        <Route path = "/du/optionals/subjects/notes" element={<NotesOptionals />} />
        <Route path = "/du/optionals/subjects" element={<SubjectsOptionals />} />

        <Route path = "/blogs/all" element={<Blogs />} />
        <Route path = "/blogs/create" element={<CreateBlog />} />
        <Route path = "/blogs/edit" element={<EditBlog />} />
        <Route path = "/blogs" element={<BlogDetails />} />
        
        <Route path = "/" element={<Homepage />} />
      </Routes>
    </div>
  );
}

export default App;
