import { useState } from "react";
import { Link } from "react-router-dom";
import "./SemesterAccordion.css";

const SemesterAccordion = ({title, list, disciplineId, semester, discipline}) => {

    const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="semesterAccordion">
        <h3 onClick = {() => setIsOpen(!isOpen)}>Sem {title}</h3>
        
        <div className={isOpen ? "categoryWrapper open" : "categoryWrapper"}>
            {list.map((item) => (
                <Link 
                  key={item}
                  to={`./${item}?id=${disciplineId}&sem=${semester}&discipline=${discipline}`}
                >
                  {item}
                </Link>
            ))}
        </div>
        
    </div>
  )
}

export default SemesterAccordion;