import { useState } from "react";
import "./DisciplineAccordion.css";
import SemesterAccordion from "../SemesterAccordion/SemesterAccordion";

const DisciplineAccordion = ({discipline, list, disciplineId}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="disciplineAccordion">
            <div className="title" onClick={() => setIsOpen(!isOpen)}>
                <h3>{discipline}</h3>
            </div>

            
            <div className={isOpen ? "semesterWrapper open" : "semesterWrapper"}>
                {
                    list.map((item) => (
                        <SemesterAccordion 
                            key={item}
                            title={item}
                            list={['syllabus', 'notes', 'pyq']}
                            disciplineId={disciplineId}
                            semester={item}
                            discipline={discipline}
                        />
                    ))
                }
            </div>
            
        </div>
    )

}

export default DisciplineAccordion;