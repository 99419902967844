import "./NotesWrapperCuetUg.css";
import NotesLinkCuetUg from "../NotesLinkCuetUg/NotesLinkCuetUg";
import NewNotesCuetUg from "../NewNotesCuetUg/NewNotesCuetUg";

const CurrentNotes = ({notes, username, password, type}) => {
  return (
      <div className="notesLinksContainer element">
          {notes.notes && (
              notes.notes.map((item) => {
                  return (
                    <NotesLinkCuetUg 
                      key={item.notesID}
                      notesID={item.notesID}
                      title={item.title} 
                      link={item.link} 
                      paid={item.paid}
                      username={username}
                      password={password}
                      type={type}
                    />
                  )
              })
          )}
      </div>
  )
}

const NotesWrapperCuetUg = ({notes, username, password, type}) => {
  return (
    <>
      <h1 className="headline element">Notes</h1>

      <CurrentNotes 
        notes={notes}
        username={username}
        password={password}
        type={type}
      />

      <NewNotesCuetUg 
        username={username}
        password={password}
        type={type}
      />

      <hr></hr>
    </>
  )
}

export default NotesWrapperCuetUg;