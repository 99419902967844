import axios from 'axios';
import { Modal, Button, Box, TextField, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import AuthenticationForm from '../AuthenticationForm/AuthenticationForm';
import "./DisciplineModal.css";

const DisciplineModal = ({currentDiscipline, currentCourse, modalOpen, setModalOpen, endpoint, method}) => {

    const [discipline, setDiscipline] = useState(currentDiscipline);
    const [course, setCourse] = useState(currentCourse);
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem('password')));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    };

    const handleInputChange = (value, updaterFunction) => {
        updaterFunction(value);
    }

    const createDiscipline = async (discipline, course, username, password, endpoint, method) => {
        try {

            if(!username || !password){
                alert('Please enter username and password!');
                return;
            }

            else if(!discipline || !course){
                alert('Please fill all the details!');
                return;
            }

            const data = {
                discipline,
                course,
                username,
                password
            }
            
            const response = await axios({
                method,
                url: endpoint,
                data
            });

            if(response.status === 201){
                alert(`Discipline Created Successsfully`);
                window.location.reload();
            }
        } catch (error) {
            alert(`Failed. ${error.response.data.message}`);
        }
    }

    return(
        <>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

            <Box sx={style} spacing={2} component="form">
                <h1 className="headline">Create Discipline</h1>

                <TextField 
                    id="outlined-basic" 
                    label="Discipline Name" 
                    variant="outlined" 
                    value={discipline}
                    onChange={(e) => handleInputChange(e.target.value, setDiscipline)}
                    required
                />
            
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={course}
                    label="Course"
                    onChange={(e) => handleInputChange(e.target.value, setCourse)}
                >
                    <MenuItem value="B.A (H)">B.A (H)</MenuItem>
                    <MenuItem value="B.A (Prog)">B.A (Prog)</MenuItem>
                    <MenuItem value="B.Sc (H)">B.Sc (H)</MenuItem>
                    <MenuItem value="B.Sc (Prog)">B.sc (Prog)</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                </Select>

                <AuthenticationForm 
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                />

                <Button 
                    style={{backgroundColor: 'cadetblue', color: 'black'}} 
                    variant="contained"
                    onClick={() => createDiscipline(discipline, course, username, password, endpoint, method)}
                >
                    {method === 'POST' ? 'Create Discipline' : 'Update Discipline'}
                </Button>
            </Box>

            </Modal>
        </>
    )
}

export default DisciplineModal;