import "./AuthenticationForm.css";

const AuthenticationForm = ({username, setUsername, password, setPassword}) => {

    const handleChange = (updaterFunction, newValue, key) => {
        updaterFunction(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
    }

  return (
    <div className="authenticationForm">

        <h1 className="headline">Credentials</h1>
        
        <div className="form">
            <input 
                type = "email" 
                placeholder="username" 
                value={username} 
                onChange={(e) => handleChange(setUsername, e.target.value, 'username')}
            />

            <input 
                type = "password" 
                placeholder="password" 
                value={password} 
                onChange={(e) => handleChange(setPassword, e.target.value, 'password')}
            />
        </div>
    </div>
  )
}

export default AuthenticationForm;