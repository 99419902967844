import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import axios from "axios";

import AuthenticationForm from "../../../layouts/AuthenticationForm/AuthenticationForm";
import Content from "../../../layouts/Content/Content";
import config from "../../../../config";

import "./Pyq.css";
import PyqWrapper from "../../../layouts/PyqWrapper/PyqWrapper";

const Pyq = () => {

    const [pyq, setPyq] = useState({});
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem('password')));

    const getPyq = async () => {
        try{
            const id = searchParams.get('id');
            const sem = searchParams.get('sem');

            const endpoint = `${config.rootEndpoint}/ug/pyq/?id=${id}&sem=${sem}`
            
            const response = await axios.get(endpoint);

            return response.data;

        }catch(error){
            return {};
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getPyq();
            setPyq(data);
        })();
    }, []);

  return (
    <div className="notesPage">
        <div className="header">
            <h1>{searchParams.get('discipline')} Notes</h1>
        </div>

        <Stack spacing={6} sx={{padding: '32px'}}>
                <Content 
                    username={username}
                    password={password}
                    contentDetails={pyq.content}
                    category='pyq'
                    type='ug'
                />

                <PyqWrapper 
                    pyq={pyq.pyq}
                    username={username}
                    password={password}
                />

                <AuthenticationForm 
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                />
        </Stack>
    </div>
  )
}

export default Pyq;